import styled from 'styled-components'

import { GradientType } from '@ui/theme/gradients'

import GradientContainer from './GradientContainer'

export type PageLayoutContainerProps = {
  children: React.ReactNode
  gradient?: GradientType
}

const PageLayoutContainer = ({ children, gradient = 'neutral' }: PageLayoutContainerProps) => {
  return (
    <RootContainer>
      <GradientContainer gradient={gradient} />
      {children}
    </RootContainer>
  )
}

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
`

export default PageLayoutContainer
