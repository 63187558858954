import css from '@styled-system/css'
import styled from 'styled-components'
import dynamic from 'next/dynamic'

import { ContentBlock } from '@ui/layout/ContentBlock'
import Stack from '@ui/layout/stack'
import { respondTo } from '@ui/theme'
import { useViewerGlobalContext } from 'viewer/ViewerGlobalContext'

import { formatFooterData, getPrimaryKeywords } from './utils'
import LegalFooter from './LegalFooter'
import LinksHowItWorks from './LinksHowItWorks'
import LinksPrimaryKeyword from './LinksPrimaryKeyword'
import LinksFind from './LinksFind'
import SocialLinks from './SocialLinks'
import HelpCenterButton from './HelpCenterButton'
import { PrimaryFooterPublicFragment } from './fragments.generated'

const CountrySelect = dynamic(() => import('@ui/navigation/PrimaryFooterPublic/CountrySelect'), {
  ssr: false,
})

type PrimaryFooterPublicProps = PrimaryFooterPublicFragment

const PrimaryFooterPublic = (props: PrimaryFooterPublicProps) => {
  const viewer = useViewerGlobalContext()
  const isLoggedIn = viewer.isLoggedIn
  const { cities, costDirectories, keywords } = formatFooterData(props)
  const primaryKeywords = getPrimaryKeywords(keywords)

  return (
    <Footer>
      <ContentBlock maxWidth='page.content.maxWidth' px='4' pt='5' pb='4'>
        <Stack gap={{ _: '4', desktop: '5' }}>
          <FooterLinkGrid>
            <LinksHowItWorks />

            <LinksFind keywords={keywords} costDirectories={costDirectories} />

            <LinksPrimaryKeyword keywords={primaryKeywords} cities={cities} />
          </FooterLinkGrid>

          <Stack gap='4'>
            <Stack
              direction={{ _: 'vertical', desktop: 'horizontal' }}
              gap='4'
              align={{ _: 'left', desktop: 'space-between' }}
            >
              <SocialLinks />
              <Stack
                direction={{ _: 'vertical', desktop: 'horizontal' }}
                align={{ _: 'left', desktop: 'right' }}
                gap={5}
                valign='bottom'
              >
                {!isLoggedIn && !viewer.isLoading && (
                  <CountrySelectContainer>
                    <CountrySelect />
                  </CountrySelectContainer>
                )}
                <HelpCenterButton />
              </Stack>
            </Stack>
            <LegalFooter />
          </Stack>
        </Stack>
      </ContentBlock>
    </Footer>
  )
}

const CountrySelectContainer = styled.div`
  min-width: 198px;
  ${respondTo.desktop`
    min-width: 141px;
    `}
`

const Footer = styled.footer`
  ${css({ marginTop: 'auto', backgroundColor: 'grey.3' })}
`

const FooterLinkGrid = styled.div`
  ${css({
    display: 'grid',
    gridTemplateColumns: ['1fr', 'repeat(4, auto)', 'repeat(5, auto)'],
    columnGap: 4,
    rowGap: 5,
  })}

  ${respondTo.tablet`
    > nav {
      max-width: 200px;
    }
  `}
`

export default PrimaryFooterPublic
