import { directoryPath } from 'routes/static'

import {
  PrimaryFooterPublicCityFragment,
  PrimaryFooterPublicCostDirectoryFragment,
  PrimaryFooterPublicDaycareKeywordFragment,
  PrimaryFooterPublicFragment,
  PrimaryFooterPublicKeywordFragment,
} from './fragments.generated'

type DirectoryKeyword = PrimaryFooterPublicKeywordFragment
type DirectoryDaycareKeyword = PrimaryFooterPublicDaycareKeywordFragment
type City = PrimaryFooterPublicCityFragment
type CostDirectory = PrimaryFooterPublicCostDirectoryFragment

export type PrimaryPublicFooterKeyword = DirectoryKeyword | DirectoryDaycareKeyword

const getCitiesFromStates = (states: any[]): City[] => {
  return states.reduce<any>((acc, state) => {
    const cities = Object.values(state).filter((value: any) => value?.__typename === 'City')

    return [...acc, ...cities]
  }, [])
}

export const getPrimaryKeywords = (keywords: PrimaryPublicFooterKeyword[]) => {
  const primaryKeywordKeys = ['nanny-jobs', 'nanny', 'nanny-share-jobs']
  return primaryKeywordKeys
    .map((key) => keywords.find(({ slug }) => key === slug))
    .filter((keyword) => typeof keyword !== 'undefined') as PrimaryPublicFooterKeyword[]
}

const getCostDirectories = (keywords: CostDirectory[]) =>
  keywords.filter((keyword) => !!keyword.costDirectory)

export const getCityDirectoryPath = (keyword: PrimaryPublicFooterKeyword, city: City) => {
  if (keyword.slug === 'nanny-share-jobs' && city.slug === 'new-york') {
    // Works around edge case
    // see https://careguide.atlassian.net/browse/NL-191
    return `${directoryPath}/${keyword.slug}/${city.state.country.slug}/${city.state.slug}`.toLowerCase()
  } else {
    return `${directoryPath}/${keyword.slug}/${city.state.country.slug}/${city.state.slug}/${city.slug}`.toLowerCase()
  }
}

export const formatFooterData = (
  data: PrimaryFooterPublicFragment
): { keywords: PrimaryPublicFooterKeyword[]; costDirectories: CostDirectory[]; cities: City[] } => {
  return {
    keywords: [
      data?.babysitterKeyword,
      data?.childCareKeyword,
      data?.nannyJobsKeyword,
      data?.nannyKeyword,
      data?.nannyShareJobsKeyword,
      data?.daycaresKeyword,
    ].filter((keyword) => keyword !== null) as PrimaryPublicFooterKeyword[],
    costDirectories: getCostDirectories(
      [
        data?.babysittersCostDirectory,
        data?.childCareCostDirectory,
        data?.nannyCostDirectory,
        data?.nannyJobsCostDirectory,
      ].filter((keyword) => !!keyword?.costDirectory) as CostDirectory[]
    ),
    cities: getCitiesFromStates(
      [
        data?.california,
        data?.illinois,
        data?.newYork,
        data?.oregon,
        data?.texas,
        data?.washington,
      ].filter((state) => !!state)
    ),
  }
}
