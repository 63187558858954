import Image from 'next/image'
import styled from 'styled-components'

import { contactUsPath, facebookPath, twitterPath } from 'routes/static'
import Stack from '@ui/layout/stack'
import Link from '@ui/common/Link'
import { StackProps } from '@ui/layout/stack/Stack'

import iconFacebook from './images/facebook.svg'
import iconTwitter from './images/twitter.svg'
import iconEmail from './images/email.svg'

const ICON_SIZE = 32

export enum SocialIconsTheme {
  Dark = 'dark',
  Light = 'light',
}

type SocialIconsProps = {
  theme?: SocialIconsTheme
} & StackProps

const SocialIcons = ({ theme = SocialIconsTheme.Light, ...rest }: SocialIconsProps) => {
  return (
    <Container $theme={theme} {...rest}>
      <Link href={facebookPath} rel='noopener noreferrer' target='_blank'>
        <Image src={iconFacebook} width={ICON_SIZE} height={ICON_SIZE} alt='Nanny Lane Facebook' />
      </Link>

      <Link href={twitterPath} rel='noopener noreferrer' target='_blank'>
        <Image src={iconTwitter} width={ICON_SIZE} height={ICON_SIZE} alt='Nanny Lane Twitter' />
      </Link>

      <Link href={contactUsPath}>
        <Image src={iconEmail} width={ICON_SIZE} height={ICON_SIZE} alt='Nanny Lane Email' />
      </Link>
    </Container>
  )
}

const Container = styled(Stack).attrs({ gap: '3', direction: 'horizontal' })<{
  $theme: SocialIconsTheme
}>`
  img {
    filter: ${({ $theme }) => ($theme === SocialIconsTheme.Dark ? 'invert(70%)' : 'none')};
  }
`

export default SocialIcons
