import { communityPath, contactUsPath } from 'routes/static'
import Stack from '@ui/layout/stack'
import Link from '@ui/common/Link'
import SocialIcons from '@ui/common/SocialIcons/SocialIcons'

const SocialLinks = () => {
  return (
    <div>
      <SocialIcons />

      <Stack gap='3' direction='horizontal'>
        <Link variant='secondary' href={contactUsPath}>
          Contact Us
        </Link>
        <Link
          href={`${communityPath}?utm_campaign=footer&utm_medium=referral&utm_source=nannylane.com`}
          target='_blank'
          variant='secondary'
          rel='noopener noreferrer'
        >
          Community
        </Link>
      </Stack>
    </div>
  )
}

export default SocialLinks
