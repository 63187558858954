import { Maybe } from 'graphql/jsutils/Maybe'

import { ProfileType } from '@graph/types/global.generated'
import { payrollStatusPath } from 'routes/static'
import { backgroundCheckStatusPath } from 'routes/backgroundChecks'
import { contractPreviewPage } from 'routes/contract'

// this type contains only the fields that are required for the viewer utils
type Viewer = {
  user: Maybe<{
    id: string
    isComplete: boolean
    activeSubscription: Maybe<{ id: string }>
    profile: Maybe<{
      id: string
      type: ProfileType
      listing: Maybe<{ id: string }>
      payroll: Maybe<{ id: string }>
    }>
    payrollRegistration: Maybe<{
      id: string
    }>
    latestNannyContract: Maybe<{
      id: string
    }>
    requestedBackgroundChecks: { id: string }[]
  }>
}

export const isViewerLoggedIn = (viewer: Viewer | null) => !!viewer?.user?.id

export const isViewerNanny = (viewer: Viewer | null) =>
  viewer?.user?.profile?.type === ProfileType.Nanny

export const isViewerFamily = (viewer: Viewer | null) =>
  viewer?.user?.profile?.type === ProfileType.Family

export const isPlusUser = (viewer: Viewer | null) => !!viewer?.user?.activeSubscription?.id

export const getSingleFlowPath = (viewer: Viewer | null) => {
  if (viewer?.user?.isComplete) return null

  if (!!viewer?.user?.payrollRegistration || !!viewer?.user?.profile?.payroll?.id)
    return payrollStatusPath

  const latestBackgroundCheck = viewer?.user?.requestedBackgroundChecks[0]
  if (!!latestBackgroundCheck) return backgroundCheckStatusPath(latestBackgroundCheck.id)

  const latestNannyContract = viewer?.user?.latestNannyContract
  if (!!viewer?.user?.profile && !viewer?.user?.profile?.listing && !!latestNannyContract)
    return contractPreviewPage(latestNannyContract.id)

  return null
}
